import loadable from "@loadable/component";
import React from "react";

const FooterProxy = loadable(() => import("../footer"));

const Footer = ({ data }) => {
	return <FooterProxy data={data} />;
};

export default Footer;
