import loadable from "@loadable/component";
import React from "react";

const BottomNavigationProxy = loadable(() => import("../bottom-navigation"));

const BottomNavigation = ({ data, languageData }) => {
	return <BottomNavigationProxy data={data} languageData={languageData} />;
};

export default BottomNavigation;
