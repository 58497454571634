import loadable from "@loadable/component";
import React from "react";

const FeedbackCommentProxy = loadable(() => import("../feedback/feedback-comment"));

const FeedbackComment = ({ data, resourceId, commentStyles }) => {
	return (
		<FeedbackCommentProxy
			data={data}
			resourceId={resourceId}
			commentStyles={commentStyles}
		/>
	);
};

export default FeedbackComment;