import loadable from "@loadable/component";
import React from "react";

const FeedbackRatingProxy = loadable(() =>import("../feedback/feedback-rating"));

const FeedbackRating = ({ data, resourceId, ratingStyles }) => {
	return (
		<FeedbackRatingProxy
			data={data}
			resourceId={resourceId}
			ratingStyles={ratingStyles}
		/>
	);
};

export default FeedbackRating;
