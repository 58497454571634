import loadable from "@loadable/component";
import React from "react";

const HeaderProxy = loadable(() => import("../header"));

const Header = ({ data, pageTitle }) => {
	return <HeaderProxy data={data} pageTitle={pageTitle} />;
};

export default Header;
