import React, { useContext } from "react";
import tw, { styled } from "twin.macro";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { graphql } from "gatsby";
// import FeedbackRating from "./feedback-rating";
import FeedbackRating from "../dynamic-components/feedback-rating";
// import FeedbackComment from "./feedback-comment";
import FeedbackComment from "../dynamic-components/feedback-comment";
import { resetFeedback } from "../../store/actions/feedback";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { ThemeContext } from "../themes/theme-context";

const StyledParagraph = tw.p`text-xs font-normal font-sans leading-sans-xs text-center px-8 pt-4`;

const StyledLink = styled.a(({ textColor, fontSize }) => [
	tw`hover:text-black underline cursor-pointer`,
	textColor,
	fontSize,
]);

const Feedback = ({ data, resourceId, ratingStyles, commentStyles, path }) => {
	// console.log(ratingStyles);
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const [submitFailed, setSubmitFailed] = useState(false);

	const state = useSelector((state) =>
		state.feedback.current ? state.feedback.current : null
	);

	const dispatch = useDispatch();
	const { theme } = useContext(ThemeContext);

	const options = {
		renderMark: {
			[MARKS.BOLD]: (text) => <strong>{text}</strong>,
			[MARKS.ITALIC]: (text) => <em>{text}</em>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<StyledParagraph>{children}</StyledParagraph>
			),
			[INLINES.HYPERLINK]: ({ data }, children) => {
				
					const isSurveyLink = (typeof children?.[0] === 'string' && children?.[0]?.includes("Take a survey -- share your feedback on this page.")) || 
					children?.[0]?.props?.children === "Help us improve the Safe Space – Click here to take a short survey";

					const fontSizeString = isSurveyLink ? tw`text-sm` : tw`text-xs`;

					return <StyledLink fontSize={fontSizeString} textColor={theme.textColor} href={data.uri} target="_blank">{children}</StyledLink>			
			},
		},
	};

	// post feedback to contentful
	useEffect(() => {
		const { didSubmit, resourceId, ratingId, comment, token } = state;

		if (!didSubmit) return;

		async function postFeedback() {
			const BASE_API_GATEWAY_ENDPOINT = process.env.GATSBY_BASE_API_GATEWAY_ENDPOINT;
			const fullEndpoint = `${BASE_API_GATEWAY_ENDPOINT}${path}`;
		
			await fetch(fullEndpoint, {
				headers: {
					"content-type": "application/json",
				},
				method: "POST",
				body: JSON.stringify({ resourceId, ratingId, comment, token }),
			})
			.then((response) => {
				if (!response.ok) {
						// console.log(response);
					setSubmitFailed(true);
					throw new Error(response.statusText);
				}
				return response;
			})
			.then((res) => {
					// console.log(res);
				setSubmitSuccess(true);
			})
			.catch((error) => {
				console.error(error);
			});
		}

		postFeedback();
	}, [state, dispatch, path]);

	// hide thank you message after 1 second
	useEffect(() => {
		const { resourceId } = state;
		if (!submitSuccess) {
			return;
		}

		const tick = () => {
			setSubmitSuccess(false);
			dispatch(resetFeedback(resourceId));
		};

		let id = setInterval(tick, 1000);

		return () => {
			clearInterval(id);
		};
	}, [submitSuccess, dispatch, state]);

	return (
		<>
			{state.step === 0 && (
				<FeedbackRating
					data={data}
					resourceId={resourceId}
					ratingStyles={ratingStyles}
				/>
			)}
			{state.step === 1 && (
				<FeedbackComment
					data={data}
					resourceId={resourceId}
					commentStyles={commentStyles}
				/>
			)}
			{state.step === 2 && !submitSuccess && !submitFailed && (
				<h3>Loading...</h3>
			)}
			{submitSuccess && <h3>Thanks for your feedback!</h3>}
			{submitFailed && <h3>Oops, something went wrong!</h3>}
			{renderRichText(data.recaptchaMessage, options)}
		</>
	);
};

export const resourceFeedbackFrag = graphql`
	fragment feedbackComponent on ContentfulFeedbackComponent {
		id
		title
		ratingQuestion
		ratingScale {
			title
			rating {
				id
				contentful_id
				title
				ratingValue
			}
		}
		commentLabel
		submitButtonLabel
		skipButtonLabel
		recaptchaMessage {
			raw
		}
	}
`;

export default Feedback;
