import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const SiteMetadata = ({ data }) => {
	const description = data.nodes[0].description;

	return (
		<Helmet>
			<meta name="description" content={description} />
		</Helmet>
	);
};

export const siteMetadataFragment = graphql`
	fragment siteMetadata on ContentfulSiteMetadata {
		title
		description
	}
`;

export default SiteMetadata;
