import { actionTypes as resourceFeedbackActions } from "../reducers/feedback";

export const showFeedback = (resourceId) => {
	const action = {
		type: resourceFeedbackActions.SHOW,
		resourceId,
	};
	return action;
};

export const hideFeedback = (resourceId) => {
	const action = {
		type: resourceFeedbackActions.HIDE,
		resourceId,
	};
	return action;
};

export const setRating = (resourceId, ratingId, rating, value) => {
	const action = {
		type: resourceFeedbackActions.SET_RATING,
		resourceId,
		ratingId,
		rating,
		value,
	};
	return action;
};

export const setComment = (resourceId, comment) => {
	const action = {
		type: resourceFeedbackActions.SET_COMMENT,
		resourceId,
		comment,
	};
	return action;
};

export const doSubmit = (resourceId, didSubmit, token) => {
	const action = {
		type: resourceFeedbackActions.SUBMIT,
		resourceId,
		didSubmit,
		token,
	};
	return action;
};

export const resetFeedback = (resourceId) => {
	const action = {
		type: resourceFeedbackActions.RESET,
		resourceId,
	};
	return action;
};
