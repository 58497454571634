import loadable from "@loadable/component";
import React from "react";

const GeneralFeedbackProxy = loadable(() =>import("../bottom-navigation/general-feedback"));

const GeneralFeedback = ({ data }) => {
	return <GeneralFeedbackProxy data={data} />;
};

export default GeneralFeedback;
